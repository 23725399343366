import '../../../global.css';

export default {
  colors: {
    primary: 'hsl(340, 100%, 48%)',
    background: '#FFFFFF',
    shape: `hsl(340, 44%, 96%)`,
    title: `hsl(240, 12%, 27%)`,
    text: `hsl(240, 8%, 46%)`,
    components: {
      blockquote: {
        background: `hsl(340, 30%, 30%)`,
        text: `hsl(340, 10%, 90%)`,
      },
    },
  },
  fonts: {
    body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  },
};
